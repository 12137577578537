let swiper;

document.addEventListener('DOMContentLoaded', function (event) {
  swiper = new Swiper('.gallery-swiper', {
    loop: true,
    // loopedSlides: 5,
    loopAdditionalSlides: 3,
    slideVisibleClass: 'swiper-slide-visible',
    watchSlidesProgress: true,
    slidesPerView: 3,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      init: function () {
        updateSlideCount(this);
      },
      slideChange: function () {
        updateSlideCount(this);
      },
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  });

  function updateSlideCount(swiper) {
    const realIndex = swiper.realIndex + 1;
    const totalSlides = swiper.slides.length;
    const slideText = realIndex + ' <span>no</span> ' + totalSlides;
    swiper.slides.forEach(function (slide) {
      const slideCount = slide.querySelector('.slide-count');
      slideCount.innerHTML = slideText;
    });
  }

  document.querySelector('.toggler').addEventListener('click', function () {
    document.querySelector('body').classList.toggle('is-mobilemenu');
  });
});
